module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",
    "btn_retry": "Try again",

    "landing__tag": "#Charity<span>4Ukraine</span>",

    "landing__title": "Doll Yourself Up For Halloween!",
    "landing__subtitle": "Help us spread the word about our <a href='https://tooncoin.com/Charity4Ukraine?utm_source=art_tooncoin' target='_blank'>charity&nbsp;initiative</a> by creating and sharing your own artwork on socials!",
    "landing__text_1": "The results vary from creepy to somehow weirdly attractive.",
    "landing__text_2": "Sometimes dope, but rarely dull.",
    "landing__select_photo_button": "Choose photo",

    "processing_text_1": "Applying the effect…",
    "processing_text_2": "Your art is almost ready…",
    "processing_text_3": "Just a few more seconds…",
    "processing_text_4": "Almost done...",

    "loader_processing": "Please wait…",
    "loader__title": "Creepy<br/>artist",

    "collage__title": "Save and share",
    "collage__text_guest": "This is someone’s Evil Doll, but you could have your own!",
    "collage__tab_image": "Image",
    "collage__tab_video": "Video",
    "collage__tab_day": "Day of the Dead",
    "collage__tab_halloween": "Halloween",
    "collage__tab_doll": "Evil Doll",
    "collage__select_photo": "Try with your photo",
    "collage__download": "Download",
    "collage__refresh": "Refresh",
    "collage__back": "Back",
    "collage__next_step": "Next",
    "collage__step_1": "1/2  •  choose body",
    "collage__step_2": "2/2  •  choose background",

    "error__error": "Error",
    "modal_message": "Join our Halloween squad of spookiest & cutest. Share your result on socials!",
    "modal_button": "GOT IT",
    "modal_title": "You look <br/> BOOtiful!",

    "internal_error": "An error occurred...",
    "error__default_message": "Something went wrong. Try again.",
    "error__network_message": "Please check your network connection.",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",
  },
};

